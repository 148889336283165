import React from 'react';
import styles from './Container.module.scss';

interface IContainer {
  children: any;
  className?: any;
  bgColor?: string;
  fullPage?: boolean;
}

const Container = ({ children, className, bgColor, fullPage = false }: IContainer) => {
  return (
    <div
      className={styles.container}
      style={
        bgColor
          ? {
              backgroundColor: bgColor,
              paddingTop: '48px',
              paddingBottom: '48px',
            }
          : {}
      }
    >
      <div
        className={`${styles.containerContent} ${className ? className : ''}`}
        style={fullPage ? { minHeight: '100vh' } : {}}
      >
        {children}
      </div>
    </div>
  );
};

export default Container;
