import React, { useEffect, useState } from 'react';
import { Link, graphql } from 'gatsby';
import LandingBox from '../images/boxes/landing_boxer.svg';
import ArrowRight from '../images/icons/arrow_right.svg';
import styles from './Index.module.scss';
import Img from 'gatsby-image';
import Layout from '../components/layout/Layout';
import SEO from '../components/seo/SEO';
import Container from '../components/container/Container';

export const query = graphql`
  fragment SanityImage on SanityMainImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      _id
    }
  }
  query IndexPageQuery {
    havila: file(relativePath: { eq: "landingpage/havila1.png" }) {
      childImageSharp {
        fluid(quality: 100) {
          aspectRatio
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    posts: allSanityPost(
      limit: 4
      sort: { fields: [publishedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
    ) {
      edges {
        node {
          id
          publishedAt
          mainImage {
            alt
            asset {
              fluid(maxWidth: 327, maxHeight: 218) {
                ...GatsbySanityImageFluid_withWebp
              }
            }
          }
          title
          _rawExcerpt
          slug {
            current
          }
        }
      }
    }
  }
`;

const IndexPage = (props: any) => {
  const { data } = props;
  const defaultWindowWidth = 1024;
  const [windowSize, setWindowSize] = useState(defaultWindowWidth);

  useEffect(() => {
    const setWindowWidth = () => {
      if (typeof window !== undefined) {
        setWindowSize(window.innerWidth);
      }
    };

    const handleResize = () => {
      setWindowWidth();
    };

    if (typeof window !== undefined) {
      window.addEventListener('resize', handleResize);
    }

    setWindowWidth();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <Layout>
        <SEO
          title={
            'Your expert H2 and gas safety partner. We provide risk assessments and other services related to gas safety.'
          }
        />
        <div className="grid_text_image grid_vertical_padding sectionShort pb-96 mb-96">
          <div>
            <h1 className={styles.welcomeText}>
              Your Expert H<sub>2</sub> and Gas Safety Partner
            </h1>
            <p className={styles.descriptionText}>
            The business idea of HYEX is to provide expert advice within hydrogen and gas safety. 
            We help projects develop safe and cost-efficient solutions using quick engineering models 
            in combination with computational fluid dynamics (CFD). This includes documenting risk for 
            permitting processes with authorities, regulators, and classification societies.
            </p>
            <p className={styles.descriptionText}>
            During his nearly 30 years’ experience in the field, the founder, Olav Roald Hansen, 
            played an instrumental role establishing FLACS as a leading CFD software. Know-how from 
            hundreds of large-scale experiments allowed for the development of techniques making FLACS 
            the most accurate and efficient consequence prediction tool for dispersion and explosion. 
            HYEX is built on this knowledge. Our main ambition is to facilitate a safe transition to a 
            hydrogen society.
            </p>
            <p className={styles.descriptionText}>
              Uncertain about your risks?
              <b className={styles.quote}>-Just simulate it.</b>
            </p>
            <div className={styles.boxContainer}>
              <img src={LandingBox} alt="box"></img>
            </div>
          </div>
          <div className={styles.imageContainer}>
            <Img
              className={`headerImageHorizontal fullWidthMobile ${styles.image}`}
              fluid={data.havila.childImageSharp.fluid}
              alt="Havila kystruten"
            />
            <div className={styles.figcaption}>
              <p>
                CFD-model of hydrogen venting systems considered for Havila Kystruten. 
                Photo: Havila.
              </p>
            </div>
          </div>
        </div>
        {data.posts.edges.length > 0 && (
          <Container className="fullPage pagePaddingHorisontal">
            <div>
              <div className={styles.nyheterGrid}>
                <div className={styles.nyheterBox}>
                  <div>
                    <figure className="headerImageHorizontal fullWidthMobile">
                      <Link
                        to={`/news/${data.posts.edges[0].node.slug.current}`}
                        className={styles.nyheterLink}
                      >
                        <Img
                          fluid={data.posts.edges[0].node.mainImage.asset.fluid}
                          alt={data.posts.edges[0].node.mainImage.alt}
                        />
                      </Link>
                    </figure>
                    <p className={styles.counter}>{data.posts.edges[0].node.title}</p>
                  </div>
                </div>
                {data.posts.edges.length > 1 && (
                  <div className={styles.nyheterBox}>
                    <div>
                      <figure className="headerImageHorizontal fullWidthMobile">
                        <Link
                          to={`/news/${data.posts.edges[1].node.slug.current}`}
                          className={styles.nyheterLink}
                        >
                          <Img
                            fluid={data.posts.edges[1].node.mainImage.asset.fluid}
                            alt={data.posts.edges[1].node.mainImage.alt}
                          />{' '}
                        </Link>
                      </figure>
                      <p className={styles.counter}>{data.posts.edges[1].node.title}</p>
                    </div>
                  </div>
                )}

                {data.posts.edges.length > 2 && (
                  <div className={styles.nyheterBox}>
                    <div>
                      <figure className="headerImageHorizontal fullWidthMobile">
                        <Link
                          to={`/news/${data.posts.edges[2].node.slug.current}`}
                          className={styles.nyheterLink}
                        >
                          <Img
                            fluid={data.posts.edges[2].node.mainImage.asset.fluid}
                            alt={data.posts.edges[2].node.mainImage.alt}
                          />
                        </Link>
                      </figure>
                      <p className={styles.counter}>{data.posts.edges[2].node.title}</p>
                    </div>
                  </div>
                )}
                {windowSize < defaultWindowWidth && data.posts.edges.length > 3 && (
                  <div className={styles.nyheterBox}>
                    <div>
                      <figure className="headerImageHorizontal fullWidthMobile">
                        <Link
                          to={`/news/${data.posts.edges[3].node.slug.current}`}
                          className={styles.nyheterLink}
                        >
                          <Img
                            fluid={data.posts.edges[3].node.mainImage.asset.fluid}
                            alt={data.posts.edges[3].node.mainImage.alt}
                          />
                        </Link>
                      </figure>
                      <p className={styles.counter}>{data.posts.edges[3].node.title}</p>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {data.posts.edges.length > 0 && (
              <div className={styles.nyheterKnapp}>
                <Link to="news">
                  <div className={styles.backButton}>
                    <div className={styles.backButtonText}>More news</div>
                    <img className={styles.arrow} src={ArrowRight} alt="backbutton" />{' '}
                  </div>
                </Link>
              </div>
            )}
          </Container>
        )}
      </Layout>
    </>
  );
};

export default IndexPage;
